@import "../../data/styles.css";

.read-article-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.read-article-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.read-article-container {
	display: flex;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.read-article-back {
	padding-top: 68px;
	padding-right: 50px;
	width: 40px;
	height: 40px;
}

.read-article-back-button {
	width: 50px;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.read-article-wrapper {
	width: 100%;
	padding-top: 30px;
}

.read-article-date-container {
	border-left: 2px solid var(--quaternary-color);
	color: var(--tertiary-color);
	font-size: 16 px;
	align-items: center;
	height: 22px;
}

.read-article-date {
	font-family: var(--primary-font);
	padding-left: 15px;
}

.read-article-title {
	padding-top: 50px;
	padding-bottom: 50px;
	width: 100% !important;
}

.read-article-body {
	padding-bottom: 50px;
	font-size: 16px;
	line-height: 30px;
	font-family: system-ui;
	color: #52525b;
}
.read-project-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 50px;
	width: 100%;
}

.read-project-images {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	justify-content: center;
	align-content: center;
	gap: 20px;
}

.read-project-body img {
	width: 100%;
	cursor: pointer;
}
.read-project-description {
	font-size: 1.3em;
	line-height: 30px;
	text-align: justify;
}

.read-project-title {
	width: 100% !important;
}
.read-project-tools {
	display: flex;
}

.read-project-tools img {
	width: 30px;
	height: auto;
	object-fit: contain;
	margin-right: 5px;
}

.modal {
	position: fixed;
	z-index: 99999;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-content {
	display: block;
	width: 100%;
	max-width: 1200px;
}

.close {
	position: absolute;
	top: 15px;
	right: 35px;
	color: #ff6262;
	font-size: 40px;
	font-weight: bold;
	transition: 0.3s;
}

.close:hover,
.close:focus {
	color: #bbb;
	text-decoration: none;
	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.read-article-container {
		flex-direction: column;
	}
}
